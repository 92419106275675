<template>
  <div class="body" :style="{ height: height + 'px' }">
    <div class="main">
      <div class="main-text">学员登录</div>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="main-form"
      >
        <el-form-item prop="account">
          <el-input
            v-model="ruleForm.account"
            autocomplete="off"
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            class="main-button"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>


<script>
import { login,getCurrentUser } from "@/api/course";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        if (this.ruleForm.pass !== "") {
          this.$refs.ruleForm.validateField("pass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        account: "",
        pass: "",
      },
      rules: {
        account: [{ validator: validatePass, trigger: "blur" }],
        pass: [{ validator: validatePass2, trigger: "blur" }],
      },
      height: 0,
    };
  },
  mounted() {
    this.height = window.innerHeight - 100;
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login();
        } else {
          return false;
        }
      });
    },
    login() {
      login({
        username: this.ruleForm.account,
        password: this.ruleForm.pass,
      }).then((res) => {
        if (res != undefined && res.code == 200) {
          this.$message({ message: '登录成功',type: 'success' })
          localStorage.setItem("account", this.username);
          localStorage.setItem("web-token", res.data.token);
          localStorage.setItem("name", res.data.app.name);
          localStorage.setItem("username", res.data.app.username);
          localStorage.setItem("phone", res.data.app.phone);
          localStorage.setItem("sex", res.data.app.sex);
          localStorage.setItem("createTime", res.data.app.createTime);
          localStorage.setItem("address", res.data.app.address);
          localStorage.setItem("avatar", res.data.app.avatar);
          localStorage.setItem("userId", res.data.app.id);
          localStorage.setItem("agentId", res.data.app.agentId);
          getCurrentUser().then((res)=>{
            var agentVo = res.data.agentVo
            if(agentVo){
              localStorage.setItem('agentVoLogo',agentVo.logo)
            }
          })
          this.$router.push('home')
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  background-image: url("../../assets/logo-background.png");
  position: relative;
  .main {
    width: 442px;
    height: 464px;
    background: #ffffff;
    box-shadow: 5px 8px 10px rgba(0, 13, 97, 0.16);
    border-radius: 20px;
    position: absolute;
    top: 15%;
    right: 18%;
    .main-text {
      font-size: 28px;
      font-weight: 400;
      color: #000000;
      text-align: center;
      margin: 60px 0 80px 0;
    }
    .main-form {
      width: 342px;
      margin: 0 auto;
    }
    .main-button {
      width: 342px;
      margin-top: 60px;
    }
  }
}
</style>